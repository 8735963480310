<template>
  <div class="fil_view">
    <div class="title">
      <h2 style="text-align: center; font-size: 25px; line-height: 80px">
        文档翻译
      </h2>
    </div>
    <div class="prompts">
      <div class="prompt">
        <el-steps :active="3">
          <el-step title="上传文件" icon="el-icon-upload"></el-step>
          <el-step title="进行检测" icon="el-icon-data-analysis"></el-step>
          <el-step title="生成报告" icon="el-icon-tickets"></el-step>
        </el-steps>
        <div class="note_fu">
          <div class="note_fu01">
            <el-divider content-position="left">注意事项</el-divider>
            <div class="note">
              
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >支持文件格式包括：doc/docx、pdf、pptx/ppt、xlsx/xls常见格式。</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >如果pdf文件有密码保护，请先使用“PDF密码解除”去除加密保护再上传文件。</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >文档翻译结果具有同版式对照、仅浏览译文两种模式，可根据您的需求进行切换。</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >在线浏览文档翻译结果若出现问题，可通过“问题反馈”将您遇到的错误/问题进行上传。</span
                >
              </p>
            </div>
          </div>
        </div>

        <!-- <img style="width: 100%" src="../../../assets/imgs/上传.png" alt="" /> -->
      </div>
    </div>

    <div class="step_wrap step1_wrap">
      <div class="upload_wrap">
        <!-- <img src="../../../assets/imgs/upload.gif" alt="" class="upload_img"> -->

        <!-- 上传文件部分 -->
        <div class="upload_r">
          <el-upload
            class="upload-demo el_upload"
            ref="upload"
            drag
            :action="action_url"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="10"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :on-change="handleChange"
            :auto-upload="false"
            name="file_obj"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :on-error="uploadFail"
            :data="fileParam"
            :headers="myHeaders"
            :show-file-list="false"
            @dragover.native="dragover"
            @dragenter.native="dragenter"
          >
            <div class="file_box">
              <div class="upload_r_p">
                <p>上传文件</p>
                <div class="add_border"></div>
              </div>
              <img
                src="../../../assets/imgs/up_1.png"
                alt=""
                class="step_img"
              />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div slot="tip" class="el-upload__tip">
                <div class="el_upload_tip_p">
                  <div class="el_upload_tip_p_title">
                    <div class="line"></div>
                    <span>支持以下文件格式</span>
                    <div class="line"></div>
                  </div>
                  <div class="img_icon">
                    <img src="../../../assets/imgs/PDF.png" alt="" width="26" />
                    <img
                      src="../../../assets/imgs/word.png"
                      alt=""
                      width="26"
                    />
                    <img src="../../../assets/imgs/PPT.png" alt="" width="26" />
                    <img src="../../../assets/imgs/xls.png" alt="" width="26" />
                    <img src="../../../assets/imgs/txt.png" alt="" width="26" />
                  </div>
                </div>
              </div>
              <!-- 免责声明 -->

              <!-- <div class="statement">
                上传文件即表示您同意我们的<span @click.stop="goNotice"
                  >条款。</span
                >
              </div> -->
            </div>

            <!-- 选择语言和文件预览部分 -->

            <div class="choice_lang">
              <!-- <img src="../../../assets/imgs/slo.png" alt="" class="slogan" /> -->

              <!-- 没有上传文件时的样式 -->

              <img
                src="../../../assets/imgs/upload.gif"
                alt=""
                class="upload_img"
                v-if="fileList.length == 0"
              />

              <!-- 上传的文件的预览 -->
              <div class="file_pre_wrap" v-else>
                <ul class="file_pre">
                  <!-- 左箭头 -->
                  <li @click.stop="handleLeft" v-if="fileList.length > 5">
                    <span class="iconfont icon-xiangzuo1"></span>
                  </li>

                  <li class="file_pre_main">
                    <div class="file_pre_item_box">
                      <div
                        class="file_pre_item_box_inner"
                        :style="{ left: left_distance + 'px' }"
                      >
                        <div
                          v-for="(item, index) in fileList"
                          :key="index"
                          class="file_pre_item"
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.name"
                            placement="bottom"
                          >
                            <img
                              src="../../../assets/imgs/PDF.png"
                              alt=""
                              v-if="item.raw.type == 'application/pdf'"
                            />
                            <img
                              src="../../../assets/imgs/word.png"
                              alt=""
                              v-if="item.raw.type == 'application/msword'"
                            />
                            <img
                              src="../../../assets/imgs/word.png"
                              alt=""
                              v-if="item.raw.type == ''"
                            />
                            <img
                              src="../../../assets/imgs/word.png"
                              alt=""
                              v-if="
                                item.raw.type ==
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                              "
                            />

                            <img
                              src="../../../assets/imgs/PPT.png"
                              alt=""
                              v-if="
                                item.raw.type ==
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                              "
                            />
                            <img
                              src="../../../assets/imgs/PPT.png"
                              alt=""
                              v-if="
                                item.raw.type == 'application/vnd.ms-powerpoint'
                              "
                            />
                            <img
                              src="../../../assets/imgs/xls.png"
                              alt=""
                              v-if="item.raw.type == 'application/vnd.ms-excel'"
                            />
                            <img
                              src="../../../assets/imgs/xls.png"
                              alt=""
                              v-if="
                                item.raw.type ==
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                              "
                            />
                            <img
                              src="../../../assets/imgs/txt.png"
                              alt=""
                              v-if="item.raw.type == 'text/plain'"
                            />
                          </el-tooltip>

                          <p class="file_name">{{ item.name }}</p>
                          <span
                            class="iconfont el-icon-circle-close"
                            @click.stop="delFilePre(index)"
                          ></span>
                        </div>
                      </div>
                    </div>

                    <!-- 添加文件 -->
                    <div class="add_file" v-if="fileList.length < 10">
                      <span class="iconfont el-icon-plus icon_add"></span>
                    </div>
                  </li>

                  <!-- 右箭头 -->
                  <li @click.stop="handleRight" v-if="fileList.length > 5">
                    <span class="iconfont icon-xiangzuo1 icon_r"></span>
                  </li>
                </ul>
                <div class="file_pre_tip">
                  <p>
                    *最多上传10个文件，单个pdf格式文件不能大于40M,其他单文件不能大于40M
                  </p>
                  <p class="red">
                    *友情提示：测试版本暂不支持扫描版、图片格式文件
                  </p>

                  <div @click.stop="delAllFilePre" class="batch_del">
                    <span class="iconfont icon-shanchu"></span
                    >点击此处可批量删除已上传文件
                  </div>
                </div>
              </div>

              <!-- 选择语种 -->
              <div class="lang_select">
                <div class="from_lang_wrap">
                  <el-select
                    v-model="fileParam.langFrom"
                    placeholder="请选择"
                    @change="selectChange"
                    filterable
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div class="lang_select_icon">
                  <span
                    class="iconfont el-icon-sort"
                    style="
                      -webkit-transform: rotate(90deg);
                      -moz-transform: rotate(90deg);
                      -o-transform: rotate(90deg);
                      transform: rotate(90deg);
                    "
                    @click.stop="twoWay"
                  ></span>
                </div>

                <div class="from_lang_wrap">
                  <el-select
                    v-model="fileParam.langTo"
                    placeholder="请选择"
                    @change="selectChange_"
                    filterable
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="trans_btn"
                  @click.stop="handleTrans"
                  v-if="fileList.length > 0"
                >
                  翻译
                </div>
              </div>
            </div>
          </el-upload>

          <!-- 文件格式不对，禁止上传 -->
          <div class="upload_error" v-if="!file_allow">
            <img src="../../../assets/imgs/no.png" alt="" />
            <p>只有PDF、doc、docx、ppt、pptx、xls、xlsx文件可以被翻译</p>
          </div>
        </div>
      </div>
    </div>
    <div class="notice_bg">
      <div class="notice">
        <!-- <h2>注意事项</h2> -->
        <div class="notice_p">
          <!-- <p> <img src="../../assets/imgs/yellow.png" alt="">
                        <span>在线翻译结果支持手机分享。</span>
                    </p> -->
          <div class="exemption">
            <img src="../../../assets/imgs/yellow.png" alt="" />
            <span>免责声明:</span>
            <p>
              1.
              文献内容以原文为准，机器翻译结果仅供参考，对依赖网站翻译信息的准确性或可靠性所造成的任何损失不承担任何责任。
            </p>
            <p>2. 平台对译文的内容与用途不予负责。</p>
            <p>
              3.
              译文中的内容仅代表原文作者观点，不代表平台观点与看法，与平台开发方立场无关。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import { getLanguages } from "@/Api/translation";
export default {
  data() {
    return {
      fileList: [], // 上传的文件列表
      fromPanelFlag: false, // 源语的弹窗面板
      from_lang_input: null,
      action_url: "https://eastrans.newacademic.net/api/translate/upload/",
      fileParam: {
        langFrom: "en",
        langTo: "zh",
        file_num: null,
      },
      myHeaders: { USID: window.localStorage.getItem("token_USID") },
      dialog_upload_success: false,
      upload_success_state: null,
      upload_success_txt: null,
      upload_fail: null,
      historyFileList: [], // 文件历史列表
      hasFileRes: false, // 返回文件历史响应
      // langFromLabel: 'en',
      // langToLabel: 'zh',
      file_allow: true,
      timer: null, // 翻译文件的定时器
      timer2: null, // 翻译文件的定时器

      colors: [
        { color: "#1989fa", percentage: 20 },
        { color: "#1989fa", percentage: 40 },
        { color: "#1989fa", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#1989fa", percentage: 100 },
      ],
      dialog_upload: false, // 正在上传的弹框
      progress_p_r: 250, // 进度条距离右侧距离
      transFlag: false, // 是否点击了翻译按钮
      previewList: [], // 上传的文件预览
      fileName: [], // 上传的文件名
      options: [],
      left_distance: 0, // 轮播图距离左侧的距离
      noticeInfo: null,
      cascaderValue: null,
      typeOptions: [
        {
          value: "translate",
          label: "下载译文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "Word版",
            },
          ],
        },
        {
          value: "origin",
          label: "下载原文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "word版",
            },
          ],
        },
        {
          value: "compare",
          label: "下载对照版(除pdf中译英外)",
        },
      ],
    };
  },
  mounted() {},
  created() {
    this.getLanguageOption();
  },
  methods: {
    selectChange() {
      if (this.fileParam.langFrom == this.fileParam.langTo) {
        this.fileParam.langFrom = ''
        this.$message.error("语言不能相同");
      }
    },
    selectChange_() {
      if (this.fileParam.langFrom == this.fileParam.langTo) {
        this.fileParam.langTo = ''
        this.$message.error("语言不能相同");
      }
    },

    // 获取语言的选择数据
    getLanguageOption() {
      let par = {
        aim: "file",
      };
      getLanguages(par).then((res) => {
        if (res.data.status == 0) {
          this.options = res.data.data;
        } else {
          this.options = [];
        }
      });
      //   this.$http
      //     .get("translate/all_lang/", {
      //       params: {
      //         aim: "file",
      //       },
      //     })
    },

    handleRemove(file, fileList) {
      this.fileList = fileList;
    },

    handlePreview(file) {},

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async handleChange(file, fileList) {
    //   console.log(file);
      //   console.log(file.name);
      //   let fileName = file.name;
      //   let pos = fileName.lastIndexOf(".");
      //   let lastName = fileName.substring(pos, fileName.length);
      //   console.log(lastName);
      //   const isAllow =
      //     file.raw.type == "application/msword" ||
      //     file.raw.type == "application/pdf" ||
      //     file.raw.type ==
      //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      //     file.raw.type ==
      //       "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      //     file.raw.type == "application/vnd.ms-excel" ||
      //     file.raw.type ==
      //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      //     file.raw.type == "application/vnd.ms-powerpoint" ||
      //     file.raw.type == "text/plain"
      //     // ||lastName == ".doc" || lastName == ".docx"
      //     ;
      let filename = file.raw.name;
      let suffix = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();
      const maxFileSize = 40 * 1024 * 1024; 
      let pageCount = 0;
      if (suffix == "pdf") {
        // let page_ = this.handlePdfUpload(file);
        pageCount = await this.handlePdfUpload(file);
        console.log(pageCount);
        if (pageCount > 50) {
          this.$message.error("pdf文件页数不能超过50页");
          this.fileList = fileList.filter((item) => item.name !== file.name);
          // console.log(fileList)
          this.deduplication_();
          return false;
        }
      }
      // return;
      if (file.size > maxFileSize) {
        this.$message.error("单个文件大小不能超过40MB");
        return false;
      }
    //   console.log(suffix)
      const isAllow =
        suffix == "pdf" ||
        suffix == "doc" ||
        suffix == "docx" ||
        suffix == "ppt" ||
        suffix == "pptx" ||
        suffix == "xls" ||
        suffix == "xlsx" ||
        suffix == "txt";

      //   console.log(isAllow);
      if (!isAllow) {
        this.file_allow = false;
        setTimeout(() => {
          this.file_allow = true;
        }, 1500);
      } else {
        this.fileList = fileList;
        // if (!this.transFlag) {
        //     setTimeout(() => {
        //         document.querySelector('.step2_wrap').scrollIntoView({ behavior: 'smooth' })

        //     }, 1000)
        // }
      }

      // 不符合的文件进行过滤
      this.fileList = fileList.filter((item) => {
        //   console.log(item.raw.name)
        let filename = item.raw.name;
        let suffix = filename
          .substr(filename.lastIndexOf(".") + 1)
          .toLowerCase();
        return (
          suffix == "pdf" ||
          suffix == "doc" ||
          suffix == "docx" ||
          suffix == "ppt" ||
          suffix == "pptx" ||
          suffix == "xls" ||
          suffix == "xlsx" ||
          suffix == "txt"
        );

        // let fileName = item.raw.name;
        // let pos = fileName.lastIndexOf(".");
        // let lastName = fileName.substring(pos, fileName.length);
        // // console.log(lastName)
        // return (
        //   item.raw.type == "application/msword" ||
        //   item.raw.type == "application/pdf" ||
        //   item.raw.type ==
        //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        //   item.raw.type ==
        //     "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        //   item.raw.type == "application/vnd.ms-excel" ||
        //   item.raw.type ==
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        //   item.raw.type == "application/vnd.ms-powerpoint" ||
        //   item.raw.type == "text/plain" ||
        //   lastName == ".doc" || lastName == ".docx"
        // );
      });

      // 去除重复文件
      this.deduplication_();
      // for (var i = 0; i < this.fileList.length - 1; i++) {
      //   for (var j = i + 1; j < this.fileList.length; j++) {
      //     if (this.fileList[i].name == this.fileList[j].name) {
      //       this.fileList.splice(j, 1);
      //       //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
      //       j--;
      //     }
      //   }
      // }
      // this.fileParam.file_num = this.fileList.length;
    },
    deduplication_() {
      for (var i = 0; i < this.fileList.length - 1; i++) {
        for (var j = i + 1; j < this.fileList.length; j++) {
          if (this.fileList[i].name == this.fileList[j].name) {
            this.fileList.splice(j, 1);
            //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
            j--;
          }
        }
      }
      this.fileParam.file_num = this.fileList.length;
    },
    handlePdfUpload(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          try {
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const pageCount = pdf.numPages;
            resolve(pageCount); // 返回 PDF 页数
          } catch (error) {
            console.error("PDF 加载失败: ", error);
            reject(0); // 返回错误时的默认值
          }
        };

        fileReader.onerror = (error) => {
          reject(error); // 处理读取文件时的错误
        };

        fileReader.readAsArrayBuffer(file.raw);
      });
    },
    // 翻译之前删除文件
    delFilePre(i) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.fileList.splice(i, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除所有的预览文件
    delAllFilePre() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.fileList = [];
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 文件上传之前完善参数
    beforeUpload(file) {},

    // 确认翻译
    handleTrans() {
      this.progress_p_r = 250;

      this.transFlag = true;
      this.$refs.upload.submit();
      this.dialog_upload = true;
      setInterval(() => {
        if (this.progress_p_r == 0) {
          return;
        }
        this.progress_p_r = this.progress_p_r - 20;
      }, 100);
    },

    // 文件上传成功
    uploadSuccess(res, file, fileList) {
        console.log(res)
      if (res.status == 0) {
        this.upload_fail = false;
        setTimeout(() => {
          this.dialog_upload = false;
          this.fileList = [];
          //   document
          //     .querySelector(".anchor")
          //     .scrollIntoView({ behavior: "smooth" });
          this.transFlag = false;
        }, 1000);
        // this.getFileList();
        this.$message({
          message: "上传成功! 请在查看报告中查看",
          type: "success",
        });
      } else if (res.status == 4) {
        this.dialog_upload = false;

        // this.$message.error("登录状态已失效,请重新登录");
        // window.localStorage.clear();
        // router.replace({
        //   name: "login",
        // });
      } else {
        this.dialog_upload = false;
        this.upload_fail = true;
        this.dialog_upload_success = true;
        this.upload_success_txt = res.msg;
        this.transFlag = false;
        this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },

    uploadFail(err, file, fileList) {
      this.dialog_upload = false;
      this.upload_fail = true;
      this.dialog_upload_success = true;
      this.fileList = [];
      this.upload_success_txt = "文件上传失败，请稍后再试！";
      this.transFlag = false;
    },
    // 取消上传操作
    hanldeCancle() {
      this.fileList = [];
      this.dialog_upload_success = false;
    },
    // 继续操作
    handleContinue() {
      this.dialog_upload_success = false;
      this.fileList = [];

      document
        .querySelector(".trans_progress")
        .scrollIntoView({ behavior: "smooth" });
    },

    // 常用语种
    oftenLang(from, to) {
      this.fileParam.langFrom = from;
      this.fileParam.langTo = to;
    },

    // 双向转换语种

    twoWay() {
      let data = this.fileParam.langFrom;
      this.fileParam.langFrom = this.fileParam.langTo;
      this.fileParam.langTo = data;
    },
    // 滚动到注意事项
    goNotice() {
      document
        .querySelector(".notice_bg")
        .scrollIntoView({ behavior: "smooth" });
    },
    // 关闭进度条的弹框
    handleClose() {
      this.fileList = [];
      this.transFlag = false;
      this.dialog_upload = false;
    },
    //取消上传
    cancleUpload() {
      this.dialog_upload = false;
      this.transFlag = false;
      this.fileList = [];
    },
    dragover(e) {
      // e.preventDefault();
      // e.stopPropagation();
      // console.log('e', e)
      // var df = e.dataTransfer;
      // console.log('dragoverdr', df)
    },
    dragenter(e) {
      // e.preventDefault();
      // e.stopPropagation();
      // var df = e.dataTransfer;
      // console.log('dragenter', df)
    },

    // 点击左侧
    handleLeft() {
      if (this.left_distance == 0) {
        return;
      }
      this.left_distance = this.left_distance + 100;
    },

    // 点击右侧
    handleRight() {
      let dis = (this.fileList.length - 5) * -100;
      if (this.left_distance == dis) {
        return;
      }
      this.left_distance = this.left_distance - 100;
    },
    // 获取公告内容
    getNoticeInfo() {
      //   this.$http.get("home/Issue/").then((res) => {
      //     if (res.data.status == 0) {
      //       this.noticeInfo = res.data.data;
      //     } else {
      //       this.noticeInfo = null;
      //     }
      //   });
    },
    handleDownloadType(val, id, index) {
      if (val) {
        this.handleDownLoad(id, val[0], val[1]);
      }

      this.cascaderValue = null;

      let obj = {};
      obj.stopPropagation = () => {};
      try {
        this.$refs.cascader[index].clearValue();
      } catch (err) {
        this.$refs.cascader[index].handleClear();
      }
    },
    casBlur() {
    //   console.log("失去焦点");
      this.cascaderValue = null;
    //   console.log(this.cascaderValue);

      let obj = {};
      obj.stopPropagation = () => {};
    //   console.log(this.$refs.cascader[index]);
      try {
        this.$refs.cascader[index].clearValue();
      } catch (err) {
        this.$refs.cascader[index].handleClear();
        // console.log("清空盒子");
      }
    },
  },
  components: {},
};
</script>

<style  lang="less" scoped>
.fil_view {
  width: 100%;
  // height: 1200px;
  min-height: calc(100vh - 200px);
  min-width: 1000px;
  background: white;
  // margin: 10px;
}
.prompts {
  display: flex;
  justify-content: center;
}
.prompt {
  // min-width: 1100px;
  width: 90%;
  max-width: 1200px;
  // margin: 0 20%;
  .el-steps {
    margin: 0px 50px;
    color: goldenrod !important;
    /deep/ .el-step__head {
      color: #409eff;
      border-color: #409eff;
    }
    /deep/ .el-step__title {
      color: #409eff;
      font-size: 20px;
      position: relative;
      left: -20px;
    }
    /deep/ .el-step__icon-inner {
      font-size: 30px;
      margin: 0 5px;
    }
  }
  .prompt_text {
    margin: 5px 50px;
    display: flex;
    .arrow {
      display: inline-block;
      width: 80px;
      height: 40px;
      line-height: 32px;
      text-align: center;
      border: 3px solid rgb(207, 207, 207);
      border-right: none;
      color: rgb(107, 107, 107);
      font-size: 18px;
    }
    i {
      display: inline-block;
      font-size: 55px;
      position: relative;
      top: -7px;
      left: -20px;
      font-weight: lighter;
      color: rgb(207, 207, 207);
    }
    .text {
      line-height: 40px;
      display: inline-block;
      font-size: 17px;
    }
  }
}
.step1_wrap {
  position: relative;
  margin-top: 20px;
  background: url("../../../assets/imgs/step_2_bg.png") no-repeat;
  //   background: #f6f7fb;
  background: white;
  background-size: cover;
}
.upload_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px dashed #ccc;
  width: 1010px;
  margin: 0 auto;
  padding: 20px 20px 40px;
  // background: #fff;
  text-align: center;
  .upload_img {
    width: 380px;
    height: 200px;
    margin-right: 40px;
    margin-top: -24px;
  }
  .step_img {
    width: 80px;
  }
  .upload_r {
    position: relative;
    // box-shadow: 0px 0px 8px 0px #dadcdd;
    // padding: 20px 0;
    // background: url("../../../assets/imgs/index-bg4.png");
    .file_box {
      position: relative;
      width: 400px;
      box-shadow: 0px 0px 8px 0px #dadcdd;
      padding: 80px 0 20px;
      margin-left: 10px;
      background: url("../../../assets/imgs/index-bg4.png");
    }
    .upload_r_p {
      width: 280px;
      position: absolute;
      left: -5px;
      top: 15px;
      p {
        height: 38px;
        line-height: 38px;
        padding-left: 25px;
        background-color: #1464a0;
        color: #fff;
        font-size: 16px;
      }
      .add_border {
        height: 4px;
        background-color: #333;
        filter: alpha(opacity=20);
        opacity: 0.2;
      }
    }
    .upload_error {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed red;
      background: #fff2f2;
      img {
        width: 100px;
      }
      p {
        font-size: 16px;
      }
    }
    .statement {
      margin-top: 20px;
      font-size: 13px;
      color: #999;
      span {
        color: #e65028;
      }
    }
  }
}
.choice_lang {
  width: 650px;
  margin-left: 10px;
  .slogan {
    width: 440px;
  }
}
.upload_r {
  position: relative;
  // box-shadow: 0px 0px 8px 0px #dadcdd;
  // padding: 20px 0;
  // background: url("../../../assets/imgs/index-bg4.png");
  .file_box {
    position: relative;
    width: 400px;
    box-shadow: 0px 0px 8px 0px #dadcdd;
    padding: 80px 0 20px;
    margin-left: 10px;
    background: url("../../../assets/imgs/index-bg4.png");
  }
  .upload_r_p {
    width: 280px;
    position: absolute;
    left: -5px;
    top: 15px;
    p {
      height: 38px;
      line-height: 38px;
      padding-left: 25px;
      background-color: #1464a0;
      color: #fff;
      font-size: 16px;
    }
    .add_border {
      height: 4px;
      background-color: #333;
      filter: alpha(opacity=20);
      opacity: 0.2;
    }
  }
  .upload_error {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed red;
    background: #fff2f2;
    img {
      width: 100px;
    }
    p {
      font-size: 16px;
    }
  }
  .statement {
    margin-top: 20px;
    font-size: 13px;
    color: #999;
    span {
      color: #e65028;
    }
  }
}
.el_upload {
  margin: 0px auto;
  text-align: center;
  // background: #fff;
  // padding: 20px;
  /deep/ .el-upload-dragger {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    border: none;
    background: transparent;
  }
  /deep/ .el-upload-list {
    margin: 0 150px;
  }
  /deep/ .el-upload-list__item:focus {
    outline: none;
    border: 1px solid #999;
    border-radius: 4px;
    box-sizing: border-box;
  }
  /deep/ .el-upload:focus {
    color: #333;
  }
}
.file_pre_wrap {
  margin-top: 10px;
  .clearAll_btn {
    position: absolute;
    right: 0;
    top: -19%;
    z-index: 2;
  }
  .file_pre_main {
    display: flex;
  }
  .file_pre_item_box {
    display: flex;
    margin: 0 10px;
    max-width: 500px;
    overflow: hidden;
    .file_pre_item_box_inner {
      position: relative;
      left: 0;
      display: flex;
      transition: all ease 0.5s;
    }
  }

  .file_pre {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 10px;
    background: #eee;

    .file_pre_item {
      flex: none;
      position: relative;
      padding: 10px;
      text-align: center;
      width: 100px;
      img {
        width: 40px;
      }
      .iconfont {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #666;
        cursor: pointer;
      }
      .file_name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
// 选择语言

.lang_wrap {
  position: relative;
  width: 1000px;
  margin: 0px auto 0;
  padding-bottom: 10px;
  // background: #fff;
}
.lang_select {
  display: flex;
  width: 90%;
  height: 60px;
  margin: 0px auto;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  .from_lang_wrap {
    width: 40%;
    .from_lang_title {
      font-size: 18px;
    }
    .from_lang {
      display: flex;
      padding: 0 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 20px;
      // width: 290px;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
  .lang_select_icon {
    align-self: flex-end;
    margin: 0 30px 8px 30px;
    &:hover {
      color: #558afb;
    }
    .iconfont {
      font-size: 24px;
      cursor: pointer;
    }
  }
  /deep/ .el-select {
    // margin-top: 20px;
  }
}
.el_upload_tip_p {
  margin-top: 14px;
  .el_upload_tip_p_title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(153, 153, 153);
    .line {
      display: inline-block;
      width: 50px;
      height: 1px;
      background: #e5e5e5;
    }
    span {
      margin: 0 4px;
    }
  }
  .img_icon {
    margin-top: 8px;
    img {
      margin: 0 2px;
      
    }
  }
}
.trans_btn {
  border-radius: 20px;
  width: 200px;
  height: 38px;
  margin-left: 30px;
  text-align: center;
  line-height: 38px;
  letter-spacing: 4px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(90deg, #0083fb, #00adfb);
  cursor: pointer;
}
.notice_bg {
  height: 200px;
  background: url("../../../assets/imgs/big1.png") bottom no-repeat;
  background-size: cover;
  .notice {
    width: 1000px;
    margin: 30px auto;
    padding: 10px 10px 30px;

    h2 {
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 2px;
      color: #fff;
    }
    .notice_p {
      margin-top: 20px;
      padding: 20px 58px;
      border: 2px solid #fff;
      p {
        display: flex;
        line-height: 30px;
        color: #fff;
        img {
          position: relative;
          top: 9px;
          display: block;
          margin-right: 6px;
          width: 12px;
          height: 12px;
        }
      }
      .exemption {
        color: #fff;
        p {
          padding-left: 36px;
        }
      }
    }
  }
}
.red {
  color: #ef4d39;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 700;
}
.note_fu {
  display: flex;
  justify-content: center;
  margin: 20px 110px 20px 80px;
  .note_fu01 {
    width: 100%;
    // max-width: 1070px;
    // margin: 30px auto !important;
  }
}
/deep/ .el-divider {
  // min-width: 800px;
  margin: 0 !important;
  //   width: 90% !important;
}
.note {
  //   width: 90%;
  //    min-width: 800px;
  padding: 10px 30px;
  border-left: 1px #dcdfe6 solid;
  border-right: 1px #dcdfe6 solid;
  border-bottom: 1px #dcdfe6 solid;
  p {
    line-height: 50px;
    // min-width: 1000px;
    img {
      margin: 0 20px;
    }
  }
}
.icon_add {
  display: inline-block;
  color: #777;
  // transform: rotate(45deg);
  
}
.add_file {
  flex: none;
  display: flex;
  border: 2px dashed #ccc;
  width: 60px;
  height: 60px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color:#409eff !important;font-weight:500 !important;

}
</style>