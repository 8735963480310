<template>
    <div class="fil_upload">
        <p class="trans_title">
            报告列表
            <!-- <img src="../../../assets/imgs/circle.png" alt="" /> -->
        </p>
        <div class="tables">
            <el-table ref="multipleTable" :data="historyFileList" tooltip-effect="dark">
                <el-table-column label="文件名称" prop="file_name" width="" align="center">
                </el-table-column>
                <el-table-column label="文件格式" align="center" width="80">
                    <template slot-scope="scope">
                        <img src="../../../assets/imgs/PDF.png" alt="" v-if="scope.row.file_type == 'pdf'" width="28" />
                        <img src="../../../assets/imgs/word.png" alt="" v-if="scope.row.file_type == 'docx' || scope.row.file_type == 'doc'
                " width="28" />
                        <img src="../../../assets/imgs/PPT.png" alt="" v-if="scope.row.file_type == 'pptx' || scope.row.file_type == 'ppt'
                " width="28" />
                        <img src="../../../assets/imgs/xls.png" alt="" v-if="scope.row.file_type == 'xlsx' || scope.row.file_type == 'xls'
                " width="28" />
                        <img src="../../../assets/imgs/txt.png" alt="" v-if="scope.row.file_type == 'txt'" width="28" />
                    </template>
                </el-table-column>
                <el-table-column label="源语言" align="center" width="70">
                    <template slot-scope="scope">
                        <img :src="scope.row.langFromImg" alt="" width="42" />
                    </template>
                </el-table-column>
                <el-table-column label="目的语言" align="center" width="80">
                    <template slot-scope="scope">
                        <img :src="scope.row.langToImg" alt="" width="42" />
                    </template>
                </el-table-column>
                <el-table-column label="页数" align="center" width="70">
                    <template slot-scope="scope">
                        {{ scope.row.pageNum ? scope.row.pageNum : 0 }} 页
                    </template>
                </el-table-column>
                <el-table-column label="上传时间" prop="create_time" width="120" align="center">
                    <!-- <template slot-scope="scope">
           
          </template> -->
                </el-table-column>
                <el-table-column label="翻译进度" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="正在翻译" placement="right">
                            <el-progress type="circle" :percentage="scope.row.process"
                                v-if="scope.row.task_status == '0'" :width="40" :color="colors"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="翻译完成" placement="right">
                            <el-progress type="circle" :percentage="100" status="success"
                                v-if="scope.row.task_status == '1'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="已删除" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '2'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="翻译失败" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '3'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="未知错误" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '4'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="正在上传" placement="right">
                            <el-progress type="circle" :percentage="10" v-if="scope.row.task_status == '5'"
                                :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="不支持次文件格式" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '6'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="文件损坏" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '7'" :width="40"></el-progress>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="余额不足，无法查看" placement="right">
                            <el-progress type="circle" :percentage="50" status="exception"
                                v-if="scope.row.task_status == '9'" :width="40"></el-progress>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="msg" width="70" align="center">
                </el-table-column>

                <el-table-column label="操作" align="left" width="270">
                    <template slot-scope="scope">
                        <div class="cells">
                           
                            <span @click="handleDel(scope.row.task_id)" class="download_icon icon_del"
                                style="margin-right: 15px">
                                <span class="iconfont el-icon-delete"></span>
                            </span>

                            <div v-if="scope.row.task_status == '1' || scope.row.process > 84">
                                <div v-if="scope.row.file_type == 'docx' ||
                scope.row.file_type == 'doc' ||
                scope.row.file_type == 'pdf'
                " class="down_box">
                                    <el-cascader v-model="cascaderValue" :options="typeOptions" placeholder="请选择下载类型"
                                        @change="
                handleDownloadType(
                    $event,
                    scope.row.task_id,
                    scope.$index
                )
                " size="mini" class="z-index" :key="scope.$index" ref="cascader"
                                        popper-class="train-tree"></el-cascader>

                                    <div class="download_icon pdf_xiazai">
                                        <i class="iconfont el-icon-download"></i>
                                    </div>
                                </div>

                                <el-dropdown class="download_icon" v-else style="margin-right: 45px">
                                    <i class="iconfont el-icon-download icon-xiazai"></i>
                                    <!-- <el-button size="mini" round class="down_css" style=""
                  >下载</el-button
                > -->

                                    <!-- 其他类型不支持 -->
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <span style="padding: 0 14px"
                                                @click="handleDownLoad(scope.row.task_id, 'translate')">下载译文</span>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <span style="padding: 0 14px"
                                                @click="handleDownLoad(scope.row.task_id, 'origin')">下载原文</span>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="scope.row.file_type == 'doc' ||
                scope.row.file_type == 'docx' ||
                (scope.row.file_type == 'pdf' &&
                    scope.row.langFrom !== 'zh')
                ">
                                            <span style="padding: 0 14px"
                                                @click="handleDownLoad(scope.row.task_id, 'compare')">下载对照版</span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <!-- v-if="
                  scope.row.task_status == '0' &&
                  scope.row.preview == '1' &&
                  scope.row.file_type != 'xls' &&
                  scope.row.file_type != 'xlsx' &&
                  scope.row.file_type != 'txt'
                "  -->
                            <span v-if="scope.row.task_status == '1' &&
                scope.row.file_type != 'xls' &&
                scope.row.file_type != 'xlsx' &&
                scope.row.file_type != 'txt'
                " class="link" @click="toEastrans(scope.row)">
                                在线预览
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div class="imgs">
                        <img src="../../../assets/imgs/zanwushuj.png" alt="" style="width: 50%" />
                    </div>
                </template>
            </el-table>
        </div>
    </div>
</template>

<script>
import qs from "qs";
import { getData, deleteRecord, download } from "@/Api/translation";
export default {
    data() {
        return {
            historyFileList: [], // 文件历史列表
            hasFileRes: true, // 返回文件历史响应
            colors: [
                { color: "#1989fa", percentage: 20 },
                { color: "#1989fa", percentage: 40 },
                { color: "#1989fa", percentage: 60 },
                { color: "#1989fa", percentage: 80 },
                { color: "#1989fa", percentage: 100 },
            ],
            typeOptions: [
                {
                    value: "translate",
                    label: "下载译文",
                    children: [
                        {
                            value: "pdf",
                            label: "PDF版",
                        },
                        {
                            value: "word",
                            label: "Word版",
                        },
                    ],
                },
                {
                    value: "origin",
                    label: "下载原文",
                    children: [
                        {
                            value: "pdf",
                            label: "PDF版",
                        },
                        {
                            value: "word",
                            label: "word版",
                        },
                    ],
                },
                {
                    value: "compare",
                    label: "下载对照版(除pdf中译英外)",
                },
            ],
            cascaderValue: null,
            timer: null, // 翻译文件的定时器
            dialogVisible: false,
            formLabelWidth: "120px",
            form: {
                down_type: "",
            },
        };
    },
    mounted() { },
    created() {
        this.getFileList();
        // 每30秒刷新翻译文件列表
        this.timer = setInterval(() => {
            this.getFileList();
        }, 30000);
    },
    beforeDestroy() {
        // 清空定时器
        clearInterval(this.timer);
    },
    methods: {
        // 获取文件历史列表
        getFileList() {
            let data = {
                page: 1,
                name: "",
                state: 0,
            };
            getData(data)
                .then((res) => {
                    console.log(res, '请求');
                    this.hasFileRes = true;
                    if (res.data.status == 0) {
                        this.historyFileList = res.data.data ? res.data.data : [];
                        if (this.historyFileList.length == 0) {
                            return;
                        } else {
                            this.historyFileList.forEach((item) => {
                                // 转换来源语种
                                switch (item.langFrom) {
                                    case "en":
                                        this.$set(item, "langFromCh", "英文");
                                        break;
                                    case "zh":
                                        this.$set(item, "langFromCh", "中文（简体）");
                                        break;
                                }
                                // 转换目的语种
                                switch (item.langTo) {
                                    case "en":
                                        this.$set(item, "langToCh", "英文");
                                        break;
                                    case "zh":
                                        this.$set(item, "langToCh", "中文（简体）");
                                        break;
                                }
                            });
                        }
                    } else {
                        this.historyFileList = [];
                    }

                    console.log(this.historyFileList, 'this.historyFileList');
                })
                .catch((err) => {
                    this.$message.error(err);
                });
            //   this.$http.post(
            //     "translate/search/",
            //     this.$qs.stringify({
            //       page: 1,
            //       name: "",
            //       state: 0,
            //     })
            //   );
        },

        // 删除操作
        handleDel(id) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            })
                .then(() => {
                    let doc_id = {
                        task_id: "['" + id + "']",
                    };
                    deleteRecord(doc_id)
                        .then((res) => {
                            if (res.data.data[0].status == 0) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功",
                                });
                                this.getFileList();
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                    //   this.$http
                    //     .post(
                    //       "translate/delete/",
                    //       this.$qs.stringify({
                    //         task_id: "['" + id + "']",
                    //       })
                    //     )
                    //     .then((res) => {
                    //       if (res.data.data[0].status == 0) {
                    //         this.$message({
                    //           type: "success",
                    //           message: "删除成功",
                    //         });
                    //         this.getFileList();
                    //       }
                    //     })
                    //     .catch((err) => {
                    //       this.$message.error(err);
                    //     });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        handleDownloadType(val, id, index) {
            //   console.log(this.cascaderValue);
            //   console.log(val, id, index);
            if (val) {
                this.handleDownLoad(id, val[0], val[1]);
            }

            this.cascaderValue = [];
            //   this.getFileList();
            //   let obj = {};
            //   obj.stopPropagation = () => {};
            //   this.$refs.cascader[index].clearValue(obj);

            //   let obj = {};
            //   obj.stopPropagation = () => {};
            //   try {
            //     this.$refs.cascader[index].clearValue();
            //   } catch (err) {
            //     this.$refs.cascader[index].handleClear();
            //   }
        },
        // 下载操作
        handleDownLoad(id, version, type) {
            let down_id = {
                task_id: "['" + id + "']",
                version: version,
                type: type,
            };
            download(down_id)
                .then((res) => {
                    if (res.data.status == 0) {
                        if (res.data.data[0].status == 0) {
                            window.open(res.data.data[0].url);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                });
            this.getFileList();
        },
        toEastrans(row) {
            //   window.open('http://eastrans.newacademic.net/#/upload', "_blank");
            // query:{id:item.task_id,pageNum:item.pageNum,from:item.langFrom,to:item.langTo}
            var tmp = Date.parse(new Date()).toString();
            tmp = tmp.substr(0, 10);

            let event = localStorage.getItem("code") + "|" + tmp;
            let url =
                "id=" +
                row.task_id +
                "&pageNum=" +
                row.pageNum +
                "&from=" +
                row.langFrom +
                "&to=" +
                row.langTo +
                "&code=" +
                event +
                "&type=" +
                row.file_type;
            // console.log("http://eastrans.newacademic.net/#/transition?" + url)
            window.open("http://eastrans.newacademic.net/#/transition?" + url, "_blank");
            //   window.open(" http://192.168.66.34:9001/#/transition?" + url, "_blank");
        },
    },
    components: {},
};
</script>

<style lang="less" scoped>
/deep/ .el-popper {
    /* .el-cascader-menu { */
    max-width: 300px !important;
    /* } */
}

.el-cascader {
    /deep/ .el-popper {
        /deep/ .el-cascader__dropdown {
            width: 300px;
        }
    }
}

.fil_upload {
    width: 100%;
    // height: 1200px;
    background: white;
    padding: 30px 100px;
    min-height: calc(100vh - 200px);
    // margin: 10px;
}

.trans_progress {
    width: 1100px;
    margin: 30px auto;
    padding: 0 0 20px;

    .trans_progress_title {
        display: flex;
        padding: 0 30px;
        line-height: 50px;
        border-bottom: 1px solid #ccc;
        background: #fff;

        .trans_progress_title_item {
            flex: none;
            font-weight: 700;
        }
    }

    .trans_file_info {
        display: flex;
        padding: 20px 30px 0;

        line-height: 40px;
    }

    .trans_file_info_item {
        display: flex;
        align-items: center;
    }

    .file_name {
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .handle_item {
        display: flex;

        a {
            color: #fff;
        }
    }

    .trans_file_info_list {
        background: #fff;
        padding-bottom: 20px;
    }
}

.noContentBox {
    padding: 40px;
    text-align: center;

    img {
        width: 150px;
    }

    p {
        color: #999;
    }
}

.trans_title {
    font-size: 24px;
    font-weight: bold;
    color: #55596e;
    text-align: center;
    margin-bottom: 35px;
}

.tables {
    // width: 100%;

    display: flex;
    justify-content: center;

    .el-table {
        // min-width: 1070px;
        // max-width: 1450px;
    }
}

.down_css {
    background-image: linear-gradient(to bottom, #B14A75, #B14A75);
    color: white;
}

.z-index {
    position: relative;
    z-index: 100;
}

/deep/ .el-cascader .el-input {
    opacity: 0;
    width: 46px;
}

.download_icon {
    position: relative;
    display: inline-block;
    border-radius: 14px;
    width: 46px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #daf3f8;
    cursor: pointer;

    .iconfont {
        font-size: 13px;
        color: #01b9d1;
    }
}

.cells {
    display: flex;
    justify-content: flex-start;
}

.pdf_xiazai {
    position: relative;
    left: -45px;
    //   position: absolute;
    //   left: 0;
    //   top: 7px;
    z-index: 0;
}

.icon_del {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: -2px;
    background: #fef0f0;
    border: 1px solid #fbc4c4;

    .iconfont {
        font-size: 16px;
        color: #f56c6c;
    }
}

.icon-xiazai {
    display: inline-block;
    width: 100%;
}

.tables {
    // min-width: 1200px;
}

.link {
    margin: 0 10px;
    cursor: pointer;
    display: inline-block;
    border-radius: 14px;
    height: 24px;
    padding: 0 10px;
    margin-left: 4px;
    line-height: 24px;
    text-align: center;
    background: #70c5fd;
    position: relative;
    left: -30px;
    color: white;

    a {
        font-size: 13px;
        color: #fff;
    }
}
</style>
<style lang="less">
.train-tree .el-cascader-menu {
    min-width: 120px !important;
}
</style>